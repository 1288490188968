<div class="row wingsmed-row">
  <div class="col-md-12">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <mat-icon class="mr-2">person</mat-icon>
          <span class="page-title">Dodaj nowego członka</span>
          <div class="loading-shade" *ngIf="loading">
            <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div class="wingsapp-card-content" *ngIf="!loading">

          <form (ngSubmit)="saveNewMember()" [formGroup]="newMemberForm">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Imię</mat-label>
                  <input matInput formControlName="firstName" required>
                </mat-form-field>
              </div>
              <div class="col">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Nazwisko</mat-label>
                  <input matInput formControlName="lastName" required>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>PESEL</mat-label>
                  <input matInput formControlName="personalIdNumber">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Legitymacja</mat-label>
                  <input matInput formControlName="cardNumber">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Zewnetrzne ID</mat-label>
                  <input matInput formControlName="externalId">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Koło</mat-label>
                  <mat-select matInput formControlName="section" required>
                    <mat-option *ngFor="let section of sectionList "
                                [value]="section.guid">
                      {{section.fullName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Data dołączenia</mat-label>
                  <input matInput [matDatepicker]="dp2" formControlName="enrollDate" required>
                  <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                  <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>


            <div class="col">
              <!-- <mat-form-field appearance="fill">
                  <input matInput [hidden]="true">
                  <mat-radio-group aria-label="Wybierz grupę" matInput formControlName="dailyGroup">
                      <mat-radio-button value="1">Grupa 1&nbsp;&nbsp;
                      </mat-radio-button>
                      <mat-radio-button value="2">Grupa 2</mat-radio-button>
                  </mat-radio-group>
              </mat-form-field>-->
            </div>

            <div class="form-row">
              <button mat-raised-button color="primary" type="submit"
                      [disabled]="!newMemberForm.valid">
                Zapisz
              </button>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
