import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MemberRoutingModule } from './member-routing.module';
import { MemberListComponent } from './components/list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../core/material.module';
import { MemberDetailsComponent } from './components/details.component';
import { HttpClientModule } from '@angular/common/http';
import { MemberNewComponent } from './components/new.component';

@NgModule({
  declarations: [
    MemberListComponent,
    MemberDetailsComponent,
    MemberNewComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    MemberRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    DatePipe
  ]
})
export class MemberModule { }
