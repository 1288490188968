import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/auth.guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { MemberDetailsComponent } from './member/components/details.component';
import { MemberListComponent } from './member/components/list.component';
import { MemberNewComponent } from './member/components/new.component';
import { SectionDetailsComponent } from './section/components/details.component';
import { SectionListComponent } from './section/components/list.component';
import { SectionNewComponent } from './section/components/new.component';

const routes: Routes = [
  { path: '', redirectTo: 'members', pathMatch: 'full' },

  {
    path: 'members',
    component: MemberListComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'members/details',
    component: MemberDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members/new',
    component: MemberNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sections',
    component: SectionListComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'sections/details',
    component: SectionDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sections/new',
    component: SectionNewComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', component: NotFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
