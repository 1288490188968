import {
  Component,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/core/components/base.component";
import { Member } from "../models/member";
import { GlobalService } from "src/app/core/services/global.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe, formatDate } from "@angular/common";
import { MemberService } from "../services/member.service";
import { UpdateMember } from "../models/update-member";


@Component({
  selector: "wingsapp-member-details",
  templateUrl: "./details.component.html",
})
export class MemberDetailsComponent
  extends BaseComponent
  implements OnInit {
  dataSource: any;
  examination = "";
  updateMemberForm!: UntypedFormGroup;
  refreshData: boolean = false;

  @ViewChild("medicalexamination", { read: ViewContainerRef })
  entry: ViewContainerRef | undefined;
  constructor(
    private memberService: MemberService,
    protected override globalService: GlobalService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    super(globalService);
  }

  override loadData() {
    console.log("in loading");
    this.memberService
      .getResourceById("/members", this.id)
      .pipe()
      .subscribe((data) => {
        this.dataSource = data;
        this.updateMemberForm = new UntypedFormGroup({
          firstName: new UntypedFormControl(this.dataSource.firstName),
          lastName: new UntypedFormControl(this.dataSource.lastName),
          personalIdNumber: new UntypedFormControl(this.dataSource.personalIdNumber),
          cardNumber: new UntypedFormControl(this.dataSource.cardNumber),
          externalId: new UntypedFormControl(this.dataSource.externalId),
          enrollDate: new UntypedFormControl(formatDate(this.dataSource.enrollDate, 'yyyy-MM-dd', 'en'), Validators.required)
        });
        this.loading = false;
      });
  }

  reloadData() {
    this.refreshData = true;
  }

  dataRefreshed() {
    this.refreshData = false;
  }
  saveUpdateMember() {
    var updateMember: UpdateMember = {
      guid: this.dataSource.guid,
      firstName: this.updateMemberForm.controls['firstName'].value,
      lastName: this.updateMemberForm.controls['lastName'].value,
      personalIdNumber: this.updateMemberForm.controls['personalIdNumber'].value,
      cardNumber: this.updateMemberForm.controls['cardNumber'].value,
      externalId: this.updateMemberForm.controls['externalId'].value,
      enrollDate: this.updateMemberForm.controls['enrollDate'].value
    }

    this.memberService
      .put(
        "/members",
        updateMember
      )
      .subscribe(() => {
        this.globalService.showSuccess("Dane zostały zaaktualizowane.");
        this.loading = true;
        this.loadData();
      }, (err: any) => { this.globalService.showError(err.error.translatedMessage); });
  }

  ngOnDestroy(): void {
  }
}
