import {
  Component,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/core/components/base.component";
import { Member } from "../models/member";
import { GlobalService } from "src/app/core/services/global.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe, formatDate } from "@angular/common";
import { MemberService } from "../services/member.service";
import { UpdateMember } from "../models/update-member";
import { CreateMember } from "../models/create-member";

@Component({
  selector: "wingsapp-new-details",
  templateUrl: "./new.component.html",
})
export class MemberNewComponent
  extends BaseComponent
  implements OnInit {
  dataSource: any;
  sectionList: any;
  examination = "";
  newMemberForm!: UntypedFormGroup;
  refreshData: boolean = false;

  entry: ViewContainerRef | undefined;
  constructor(
    private memberService: MemberService,
    protected override globalService: GlobalService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    super(globalService);
  }

  override loadData() {

    var todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.newMemberForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      personalIdNumber: new UntypedFormControl(),
      cardNumber: new UntypedFormControl(),
      externalId: new UntypedFormControl(),
      section: new UntypedFormControl(),
      enrollDate: new UntypedFormControl(todaysDate, Validators.required)
    });

    this.memberService
    .getResources("/sections")
    .pipe()
    .subscribe((data) => {
      this.sectionList = data;
      this.loading = false;
    });

    this.loading = false;
  }

  reloadData() {
    this.refreshData = true;
  }

  dataRefreshed() {
    this.refreshData = false;
  }
  saveNewMember() {
    var newMember: CreateMember = {
      firstName: this.newMemberForm.controls['firstName'].value,
      lastName: this.newMemberForm.controls['lastName'].value,
      personalIdNumber: this.newMemberForm.controls['personalIdNumber'].value,
      cardNumber: this.newMemberForm.controls['cardNumber'].value,
      externalId: this.newMemberForm.controls['externalId'].value,
      section: this.newMemberForm.controls['section'].value,
      enrollDate: this.newMemberForm.controls['enrollDate'].value
    }

    this.memberService
      .post(
        "/members",
        newMember
      )
      .subscribe(() => {
        this.globalService.showSuccess("Członek został dodany.");
        this.loading = true;
        this.loadData();
      }, (err: any) => { this.globalService.showError(err.error.translatedMessage); });
  }

  ngOnDestroy(): void {
  }
}
