import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Member } from "../models/member";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MemberService } from "../services/member.service";
import { Router } from "@angular/router";
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  templateUrl: "./list.component.html",
})
export class MemberListComponent implements OnInit {
  loading = true;
  displayedColumns: string[] = [
    "firstName", "lastName", "personalIdNumber", "cardNumber", "section",
    "actions",
  ];
  filterValue = "";
  dataSource!: MatTableDataSource<Member>;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('filterInput') filterInput: any;
  constructor(
    private memberService: MemberService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loading = true;

    let memberFilter = localStorage.getItem('memberFilter');
    if (memberFilter != null) {
      this.filterValue = memberFilter;
    }

    this.loadData(this.filterValue);
  }


  memberDetails(row: Member) {
    let route = "/members/details";
    this.router.navigate([route], { queryParams: { id: row.guid } });
  }


  ngAfterViewInit() {

    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.loadData(this.filterInput.nativeElement.value);
        })
      )
      .subscribe();
  }

  loadData(filter: string) {
    console.log("in loading");
    this.loading = true;
    this.memberService.findMembers(filter)
      .pipe()
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      });
  }
}
