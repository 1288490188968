import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BASE_URL } from 'src/app/core/core.token';
import { CoreHttpService } from 'src/app/core/services/core-http.service';
import { Observable } from "rxjs/internal/Observable";
import { Member } from '../models/member';


@Injectable({
  providedIn: 'root'
})
export class MemberService extends CoreHttpService {

  constructor(
    protected override http: HttpClient,
    @Inject(BASE_URL) protected override baseUrl: string
    ) {
    super(http, baseUrl);
  }

  findMembers(filter: any, pageNumber = 1, pageSize = 100): Observable<Member[]> {

    return this.http
      .get<Member[]>(this.baseUrl + "/members", {
        params: new HttpParams()
          .set("filter", filter)
          .set("pageNumber", pageNumber.toString())
          .set("pageSize", pageSize.toString()),
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

}
