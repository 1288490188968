import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { MemberDetailsComponent } from './components/details.component';
import { MemberListComponent } from './components/list.component';

const routes: Routes = [
  {
    path: 'members',
    component: MemberListComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }
