<div class="row wingsmed-row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="mr-2">group</mat-icon>
                    <span class="page-title">Członkowie</span>
                    <div class="loading-shade" *ngIf="loading">
                        <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
                    </div>
                </mat-card-title>
                <mat-card-actions>
                    <button mat-raised-button color="primary" [routerLink]="['/members/new']">Dodaj</button> 
                </mat-card-actions>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="wingsapp-card-content">
                    <mat-form-field>
                        <input #filterInput matInput name="filterInput" placeholder="Filtr" >
                    </mat-form-field>
                    <div class="mat-elevation-z4">
                      <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="firstName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Imię </th>
                          <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="lastName">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwisko </th>
                          <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="personalIdNumber">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> PESEL </th>
                          <td mat-cell *matCellDef="let row"> {{row.personalIdNumber}} </td>
                        </ng-container>
                        <ng-container matColumnDef="cardNumber">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Legitymacja </th>
                          <td mat-cell *matCellDef="let row"> {{row.cardNumber}} </td>
                        </ng-container>
                        <ng-container matColumnDef="section">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Koło </th>
                          <td mat-cell *matCellDef="let row"> {{row.section.fullName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef> Akcja </th>
                          <td mat-cell *matCellDef="let row">
                            <button mat-raised-button color="primary" (click)="memberDetails(row)">Szczegóły</button>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
                      </table>
                        <mat-paginator [pageSizeOptions]="[25, 50, 100] " showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
