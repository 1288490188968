const keycloakConfig = {
  url: 'https://login.wingsapp.eu',
  realm: 'pzn.wingsapp.eu',
  clientId: 'pzn-authentication'
};

export const environment = {
  production: false,
  apiUrl: 'https://pznapi.wingsapp.eu',
  keycloakConfig
};
