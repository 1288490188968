<div class="row wingsmed-row">
  <div class="col-md-12">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <mat-icon class="mr-2">location_city</mat-icon>
          <span class="page-title">Koła</span>
          <div class="loading-shade" *ngIf="loading">
            <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
          </div>
        </mat-card-title>
        <mat-card-actions>
          <button mat-raised-button color="primary" [routerLink]="['/sections/new']">Dodaj</button>
        </mat-card-actions>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div class="wingsapp-card-content">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event)" placeholder="Filtr">
          </mat-form-field>
          <div class="mat-elevation-z4">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nazwa koła </th>
                <td mat-cell *matCellDef="let row"> {{row.fullName}} </td>
              </ng-container>
              <ng-container matColumnDef="externalId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Zewnętrzne ID </th>
                <td mat-cell *matCellDef="let row"> {{row.externalId}} </td>
              </ng-container>
              <ng-container matColumnDef="regionName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Okręg </th>
                <td mat-cell *matCellDef="let row"> {{row.region.fullName}} </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Akcja </th>
                <td mat-cell *matCellDef="let row">
                  <button mat-raised-button color="primary" (click)="sectionDetails(row)">Szczegóły</button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns "></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[25, 50, 100] " showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
