import { KeycloakService } from "keycloak-angular";
import { environment } from "../../environments/environment";

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'login-required',
        
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      authorizationHeaderName: 'Authorization'
    });
}
