<div class="row wingsmed-row">
  <div class="col-md-12">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <mat-icon class="mr-2">location_city</mat-icon>
          <span class="page-title">Dodaj nowe koło</span>
          <div class="loading-shade" *ngIf="loading">
            <mat-spinner *ngIf="loading" [diameter]="24"></mat-spinner>
          </div>
        </mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div class="wingsapp-card-content" *ngIf="!loading">

          <form (ngSubmit)="saveNewSection()" [formGroup]="newSectionForm">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Nazwa koła</mat-label>
                  <input matInput formControlName="fullName">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Zewnętrzne ID</mat-label>
                  <input matInput formControlName="externalId">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill">
                  <mat-label>Okręg</mat-label>
                  <mat-select matInput formControlName="region" required>
                    <mat-option *ngFor="let region of regionList "
                                [value]="region.guid">
                      {{region.fullName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="form-row">
              <button mat-raised-button color="primary" type="submit"
                      [disabled]="!newSectionForm.valid">
                Zapisz
              </button>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
