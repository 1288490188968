import { AfterViewInit, Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { Params } from "@angular/router";
import { GlobalService } from "../services/global.service";

@Component({
  templateUrl: "./base.component.html",
})
export class BaseComponent implements OnInit, AfterViewInit {
  public redirectToList: Boolean = true;
  public id: string = ""; 
  public loading: Boolean = true;
  public error: Boolean = false;
  public errorMessage: string = "";


  constructor(protected globalService: GlobalService) {}
  ngAfterViewInit(): void {
    this.loading = true;
    this.loadData();
  }

  ngOnInit() {
    this.getId();
  }

  loadData() {
    throw new Error("Method not implemented.");
  }

  getId() {
    this.globalService.route.queryParams.subscribe((params: Params) => {
      this.id = params["id"];
    });
  }
}
