import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SectionsRoutingModule } from './sections-routing.module';
import { SectionDetailsComponent } from './components/details.component';
import { SectionListComponent } from './components/list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../core/material.module';
import { MemberRoutingModule } from '../member/member-routing.module';
import { SectionNewComponent } from './components/new.component';


@NgModule({
  declarations: [SectionListComponent, SectionDetailsComponent, SectionNewComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MemberRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SectionsRoutingModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    DatePipe
  ]
})
export class SectionsModule { }
