import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BASE_URL } from "src/app/core/core.token";
import { Observable } from "rxjs/internal/Observable";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs/internal/observable/throwError";
import { Exception } from "../models/exception";

@Injectable({
  providedIn: "root",
})
export class CoreHttpService {
  constructor(
    protected http: HttpClient,
    @Inject(BASE_URL) protected baseUrl: string
  ) { }

  getResources(url: string): Observable<any[]> {
    const serviceUrl = this.baseUrl + url;
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http.get<any[]>(serviceUrl, { headers }).pipe(
      map((data) => data),
      catchError(this.handleError)
    );
  }

  getResource(url: string): Observable<any> {
    const serviceUrl = this.baseUrl + url;
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .get<any>(serviceUrl, { headers })
      .pipe(catchError(this.handleError));
  }

  getResourceById(url: string, id: string): Observable<any> {
    const serviceUrl = this.baseUrl + url + "/" + id;
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .get<any>(serviceUrl, { headers })
      .pipe(catchError(this.handleError));
  }

  getResourceByCriteria(url: string, ...criteria: string[]): Observable<any[]> {
    const serviceUrl = this.baseUrl + url + "?" + criteria.join("&");
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .get<any[]>(serviceUrl, { headers })
      .pipe(catchError(this.handleError));
  }

  createResource(url: string, resource: any): Observable<any> {
    const serviceUrl = this.baseUrl + url;
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .post<any>(serviceUrl, resource, { headers })
      .pipe(catchError(this.handleError));
  }

  editRessource(url: string, ressource: any): Observable<any> {
    const serviceUrl = this.baseUrl + url + "/" + ressource.id;
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .put<any>(serviceUrl, ressource, { headers })
      .pipe(catchError(this.handleError));
  }

  deleteRessource(url: string, id: number): Observable<any> {
    const serviceUrl = this.baseUrl + url + "/" + id;
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .delete<any>(serviceUrl, { headers })
      .pipe(catchError(this.handleError));
  }

  genericSearchApi(url: string, ...query: string[]): Observable<any> {
    let serviceUrl = this.baseUrl + url + "?" + query.join("&");
    if (query.length === 0) {
      serviceUrl = this.baseUrl + url;
    }
    const headers = new HttpHeaders().set("Accept", "application/ld+json");
    return this.http.get<any>(serviceUrl, { headers }).pipe(
      map((data) => ({
        results: data["hydra:member"],
        count: data["hydra:totalItems"],
        links: data["hydra:view"],
      })),
      catchError(this.handleError)
    );
  }

  post(url: string, resource: any): any {
    const serviceUrl = this.baseUrl + url;
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .post<any>(serviceUrl, resource, { headers })
      .pipe(catchError(this.handleError));
  }

  put(url: string, resource: any): any {
    const serviceUrl = this.baseUrl + url;
    const headers = new HttpHeaders().set("Accept", "application/json");
    return this.http
      .put<any>(serviceUrl, resource, { headers })
      .pipe(catchError(this.handleError));
  }

  downloadPDF(url: string): any {
    const serviceUrl = this.baseUrl + url;
    const headers = new HttpHeaders().set('Accept', 'application/json');
    return this.http.get<any>(serviceUrl, { headers }).pipe(
      catchError(this.handleError)
    );
  }

  public logError(exception: Exception) {
    this.post("/logs/error", exception);
  }

  protected handleError(err: any) {
    return throwError(() => {
      return err;
    });
  }


}
