import {
  Component,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/core/components/base.component";
import { Section } from "../models/section";
import { GlobalService } from "src/app/core/services/global.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe, formatDate } from "@angular/common";
import { SectionService } from "../services/section.service";
import { UpdateSection } from "../models/update-section";

@Component({
  selector: "wingsapp-section-details",
  templateUrl: "./details.component.html",
})
export class SectionDetailsComponent
  extends BaseComponent
  implements OnInit {
  dataSource: any;
  examination = "";
  updateSectionForm!: UntypedFormGroup;
  refreshData: boolean = false;

  @ViewChild("medicalexamination", { read: ViewContainerRef })
  entry: ViewContainerRef | undefined;
  constructor(
    private sectionService: SectionService,
    protected override globalService: GlobalService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    super(globalService);
  }

  override loadData() {
    console.log("in loading");
    this.sectionService
      .getResourceById("/sections", this.id)
      .pipe()
      .subscribe((data) => {
        this.dataSource = data;
        this.updateSectionForm = new UntypedFormGroup({
          fullName: new UntypedFormControl(this.dataSource.fullName)
        });
        this.loading = false;
      });
  }

  reloadData() {
    this.refreshData = true;
  }

  dataRefreshed() {
    this.refreshData = false;
  }
  saveUpdateSection() {
    var updateSection: UpdateSection = {
      guid: this.dataSource.guid,
      fullName: this.updateSectionForm.controls['fullName'].value
    }

    this.sectionService
      .put(
        "/sections",
        updateSection
      )
      .subscribe(() => {
        this.globalService.showSuccess("Dane zostały zaaktualizowane.");
        this.loading = true;
        this.loadData();
      }, (err: any) => { this.globalService.showError(err.error.translatedMessage); });
  }

  ngOnDestroy(): void {
  }
}
