<div class="row wingsmed-row">
  <div class="col-md-12">
    <div class="error-template">
      <h1>Nie znaleziono strony</h1>
      <h2></h2>
      <div class="error-details">
        Strona o podanym adresie nie istnieje.
      </div>
    </div>
  </div>
</div>
