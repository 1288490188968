<div class="wingsapp-container">
  <header class="mat-primary">
    <div class="wingsapp-header-right">
      <mat-toolbar color="primary">
        <button mat-icon-button (click)="doLogout()" matTooltip="Log Out" style="margin-left: 45px;">
          <mat-icon>logout</mat-icon>
        </button>
      </mat-toolbar>

    </div>
    <div class="wingsapp-header-left">
      <p>Polski Związek Niewidomych</p>
    </div>
  </header>
  <div class="wingsapp-content">
    <nav class="wingsapp-menu">
      <mat-list class="wingsapp-mat-list">
        <mat-list-item>
          <button mat-stroked-button routerLink="members" routerLinkActive="active" class="wingsapp-menu-button">
            <mat-icon>group</mat-icon>
            <span>Członkowie</span>
          </button>
        </mat-list-item>
        <mat-list-item>
          <button mat-stroked-button routerLink="sections" routerLinkActive="active" class="wingsapp-menu-button">
            <mat-icon>location_city</mat-icon>
            <span>Koła</span>
          </button>
        </mat-list-item>
      </mat-list>
    </nav>
    <main class="wingsapp-main-content"><router-outlet></router-outlet></main>
  </div>
</div>