import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Section } from "../models/section";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SectionService } from "../services/section.service";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/core/services/global.service";

@Component({
  templateUrl: "./list.component.html",
})
export class SectionListComponent implements OnInit {
  loading = true;
  displayedColumns: string[] = [
    "fullName", "externalId", "regionName", "actions",
  ];
  filterValue = "";
  dataSource!: MatTableDataSource<Section>;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('filter') filter: ElementRef;
  constructor(
    private sectionService: SectionService,
    protected globalService: GlobalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.filter == undefined) {
        console.log(true);
      } else {
        console.log(false);
      }
    }, 5);

  }

  sectionDetails(row: Section) {
    let route = "/sections/details";
    this.router.navigate([route], { queryParams: { id: row.guid } });
  }

  applyFilter(event: Event) {
    var filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadData() {
    console.log("in loading");
    this.loading = true;
    this.sectionService
      .getResources("/sections")
      .pipe()
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      });
  }
}
