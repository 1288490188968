import {
  Component,
  ViewChild,
  ViewContainerRef,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/core/components/base.component";
import { Section } from "../models/section";
import { GlobalService } from "src/app/core/services/global.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe, formatDate } from "@angular/common";
import { SectionService } from "../services/section.service";
import { UpdateSection } from "../models/update-section";
import { CreateSection } from "../models/create-section";

@Component({
  selector: "wingsapp-new-details",
  templateUrl: "./new.component.html",
})
export class SectionNewComponent
  extends BaseComponent
  implements OnInit {
  dataSource: any;
  regionList: any;
  examination = "";
  newSectionForm!: UntypedFormGroup;
  refreshData: boolean = false;

  entry: ViewContainerRef | undefined;
  constructor(
    private sectionService: SectionService,
    protected override globalService: GlobalService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    super(globalService);
  }

  override loadData() {
    this.newSectionForm = new UntypedFormGroup({
      fullName: new UntypedFormControl(),
      region: new UntypedFormControl()
    });

    this.sectionService
    .getResources("/regions")
    .pipe()
    .subscribe((data) => {
      this.regionList = data;
      this.loading = false;
    });

    this.loading = false;
  }

  reloadData() {
    this.refreshData = true;
  }

  dataRefreshed() {
    this.refreshData = false;
  }
  saveNewSection() {
    var newSection: CreateSection = {
      fullName: this.newSectionForm.controls['fullName'].value,
      region: this.newSectionForm.controls['region'].value
    }

    this.sectionService
      .post(
        "/sections",
        newSection
      )
      .subscribe(() => {
        this.globalService.showSuccess("Koło zostało dodane.");
        this.loading = true;
        this.loadData();
      }, (err: any) => { this.globalService.showError(err.error.translatedMessage); });
  }

  ngOnDestroy(): void {
  }
}
