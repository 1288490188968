import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { SectionDetailsComponent } from './components/details.component';
import { SectionListComponent } from './components/list.component';

const routes: Routes = [{
  path: 'sections',
  component: SectionListComponent,
  canActivate: [AuthGuard],
  pathMatch: 'full'
},
{
  path: 'sections/details',
  component: SectionDetailsComponent,
  canActivate: [AuthGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SectionsRoutingModule { }
